export default {
  props: {
    recId: {
      type: Number
    }
  },

  data () {
    return {
      keyWord: '',
      pageSize: 10,
      tableData: [],
      totalRecords: 0
    }
  },

  watch: {
    currentPage: {
      handler: 'loadTableData'
    },

    currentSearch: {
      handler: 'loadTableData'
    },

    currentSort: {
      handler: 'loadTableData'
    }
  },

  created () {
    this.loadTableData()
  },

  computed: {
    editRecord: {
      get: function () {
        if (this.recId === 0) {
          return this.defaultRecord
        } else if (this.recId > 0) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].id === this.recId) {
              return this.tableData[i]
            }
          }
        }
        return null
      },

      set: function (val) {
        this.saveHandle(val)
      }
    },

    currentPage: {
      get: function () {
        return parseInt(this.$route.query.page || 1)
      },
      set: function (val) {
        this.part_route({ page: (val === 1) ? null : val })
      }
    },

    currentSearch: {
      get: function () {
        return this.$route.query.search || ''
      },
      set: function (val) {
        this.part_route({ search: val || null, page: null })
      }
    },

    currentSort: {
      get: function () {
        return this.$route.query.sort || ''
      },
      set: function (val) {
        this.part_route({ sort: val || null, page: null })
      }
    }
  },

  methods: {
    part_route (query) {
      let _query = {}
      let _push = false

      if (this.$route.query) {
        _query = Object.assign({}, this.$route.query)
      }

      if (query) {
        for (let i in query) {
          if (query[i] === null) {
            _push = true
            delete _query[i]
          } else if (_query[i] !== query[i]) {
            _query[i] = query[i]
            _push = true
          }
        }
      }
      if (_push) {
        this.$router.push({ query: _query }).catch(()=>{})
      }
    },

    sortObj (str) {
      const data = {prop: '', order: null}
      if (str.length) {
        if (str.slice(0,1) == '-') {
          data.order =  'descending'
          data.prop = str.slice(1)
        } else {
          data.order = 'ascending'
          data.prop = str
        }        
      }
      return data
    },

    sortStr (obj) {
      if (!obj.order) {
        return ''
      }
      return (obj.order == 'descending' ? '-' : '') + obj.prop
    },

    // changeSortHandler (val) {
    //   this.currentSort = val
    // },

    addItemHandle () {
      this.$router.push({ name: this.keyWord, params: { recId: 0 }, query: this.$route.query })
    },

    editRecordHandle (recId) {
      this.$router.push({ name: this.keyWord, params: { recId: recId }, query: this.$route.query }).catch(()=>{})
    },

    cancelHandle () {
      this.$router.push({ name: this.keyWord + 's', query: this.$route.query })
    },

    saveHandle (val) {
      if (val) {
        // this.$wait.show()
        const url = this.keyWord + '/' + (val.id || 0)
        // this.preUpdate(val)
        this.$axios.post(url, val)
          .then(response => {
            const { add, data } = response.data
            if (add) {
              this.loadTableData()
            } else {
              for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].id === data.id) {
                  this.$set(this.tableData, i, data)
                  break
                }
              }
            }
            // this.postUpdate(data)
            this.cancelHandle()
          })
          .catch(err => {
            console.log(err)
            if (err.response.data.error) {
              this.$notify.error({title: 'Error',  message: err.response.data.error})
            }
          })
        //   .finally(() => { this.$wait.hide() })
      }
    },

    // deleteHandler (_id) {
    //   if (_id) {
    //     this.$wait.show()
    //     this.$axios.delete(this.keyWord + '/' + _id)
    //       .then(response => {
    //         this.loadTableData()
    //       })
    //       .catch(err => {
    //         if (err.response && err.response.data.message) {
    //           this.$message(err.response.data.message)
    //         }
    //       })
    //       .finally(() => { this.$wait.hide() })
    //   }
    // },

    // reloadHandler () {
    //   this.cancelHandler()
    //   this.loadTableData()
    // },

    loadTableData () {
      if (this.keyWord) {
        // this.$wait.show()
        const dt = {
          page: {
            current: this.currentPage,
            size: this.pageSize
          },
          search: this.currentSearch,
          sort: this.currentSort
        }
        // this.preLoad(dt)
        this.$axios.post('/' + this.keyWord + 's', dt)
          .then(response => {
            const { data, total } = response.data
            this.tableData = data || []
            this.totalRecords = total || 0
            // this.postLoad()
          })
          .catch(err => {
            if (err.response && err.response.data.error) {
              this.$notify.error({title: 'Error',  message: err.response.data.error})
            }
          })
          // .finally(() => { this.$wait.hide() })
      }
    },

    // preLoad (data) {

    // },

    // postLoad () {

    // },

    // postUpdate (data) {

    // },

    // preUpdate (data) {

    // },

    // showEditRecordHandler (row, colIndex) {
    //   this.$router.push({ name: this.keyWord, params: { recId: row.id }, query: this.$route.query })
    // }
  }
}
