<template>
  <div class="departments">
    <mcb-tool-bar text="Специальности">
      <mcb-search title="Поиск по названию" v-model="currentSearch" />
      <el-button type="primary" @click="addItemHandle">Новая специальность</el-button>
    </mcb-tool-bar>

    <el-table
      :data="tableData"
      :default-sort="sortObj(currentSort)"
      empty-text="Нет данных"
      @sort-change="val => currentSort=sortStr(val)"
      style="width: 100%">
      <el-table-column prop="name" label="Название" sortable="custom">
        <template slot-scope="scope">
          <router-link :to="{ name: keyWord, params: { recId: scope.row.id }, query: $route.query }">{{scope.row.name}}</router-link>
        </template>
      </el-table-column>

      <el-table-column prop="updated_at_iso" label="Обновлено" width="170px">
        <template slot-scope="scope">
          {{ruDateTime(scope.row.updated_at_iso)}}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      hide-on-single-page
      :total="totalRecords"
      :current-page="currentPage" 
      @current-change="val => currentPage=val" />

    <department
      v-model="editRecord"
      @cancel="cancelHandle"
      v-if="editRecord !== null"/>
  </div>
</template>

<script>
import { ruDateTime } from '@/components/utils.js'
import MultiPage from '@/mixins/multi-page.js'

export default {
  mixins: [MultiPage],

  components: { Department: () => import('./Department.vue') },

  data () {
    return {
      keyWord: 'department',
      ruDateTime: ruDateTime,
      defaultRecord: {
        name: ''
      }
    }
  }
}
</script>
